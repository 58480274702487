.experience {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 5px;
    width:100%;
  }

  .title-column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .experience-name {
    font-weight: bold;
    margin-right: auto;
    font-size: medium;
  }
  
  .experience-dates {
    font-style: italic;
    font-size: medium;
  }
  
  .experience-title {
    font-weight: bold;
    font-style: italic;
  }
  