@font-face {
  font-family: 'Alata';
  src: local('Alata-Regular'),
    url(fonts/Alata-Regular.ttf) format('truetype');
    font-weight: normal;
}

body {
  margin: 0;
  background-color: whitesmoke;
  font-family: Alata;
  text-align: center;
}

.container {
  margin: 1rem;
  padding: 1rem;
}

