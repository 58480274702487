.footer {
    width: 100%;
    height: 60px;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  .footer a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer a:hover {
    color: #ccc;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 40vw;
  }
  

  @media (max-width: 700px) {
    
    .footer {
        flex-direction: column;
    }
}