
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1em;
    text-align: left;
  }
  
  h1 {
    font-size: 2em;
    margin: 0 0 0.5em;
  }
  
  h2 {
    font-size: 1.2em;
    font-weight: normal;
    margin: 0 0 1em;
  }
  
  section {
    margin: 0 0;
  }

  hr {
    border: none;
    border-bottom: 1px solid black; 
    border-top: 1px solid black;
    width: 40%;
    margin: 0 0px;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  h4 {
    font-size: 1em;
    font-weight: bold;
  }
  
  h5 {
    font-size: 1em;
    font-weight: normal;
  }
  
  ul {
    margin-left: 0.2em;
    margin-top:0;
    margin-bottom:20px;

    padding: 0;
  }
  
  li {
    margin: 0 0 0.3em;
  }
  
  p {
    margin: 0.3em 0;
  }